//Media Query
@mixin tablet {
    @media only screen and (min-width: 768px) {
        @content;
    }
}
@mixin desktop {
    @media only screen and (min-width: 1200px) {
        @content;
    }
}


@mixin button{
    background-color: white;
    color: $green;
    text-decoration: unset;
    border: 2px solid $green;
    border-radius: 10px;
    padding: 18px 36px;
    font-weight: 500;
    text-transform: uppercase;
    display: inline-block;
    letter-spacing: 1px;
    cursor: pointer;
    box-shadow: inset 0 0 0 0 #D80286;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
    &:hover{
        background-color: $blue;
        color: white;
    }
    @content;
}

@mixin mobile {
    @media screen and (max-width: 767px) {
        @content;
    }
}

@mixin tablet {
    @media screen and (max-width: 991px) {
        @content;
    }
}

@mixin desktop {
    @media screen and (min-width: 992px) {
        @content;
    }
}
