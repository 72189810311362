:root {
  --light: #eee;
  --dark: #342F4E;
  --darker: #262336;
  --blue: #61DAFB;
  --crimson: #DB3052;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Fira sans', sans-serif;
}

body {
  background-color: #322037;
}

.App {
  background-color: #322037;
  text-align: center;
  padding: 15px;
  height: 100vh;
}

