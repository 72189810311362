@import '../../abstracts/partials.scss';

h1 {
    font-size: 2.5rem;
    text-transform: uppercase;
    margin-bottom: 1rem;
    color: white;
}
  
.functions {
    color: #322037;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin: 2rem;
}
    .functions-wrapper{
        overflow: hidden;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .function-elements{
        background-color: #DCDCDC;
        padding: 1rem;
        border-radius: 0.5rem;
        margin-bottom: 15px;
        margin-top: 15px;
        width: 100%;
    }

    @media(min-width: 768px){
        .function-elements {
            max-width: 740px; 
        }
    }


.function {
    background-color: #DCDCDC;
    padding: 1rem;
    border-radius: 0.5rem;
    text-align: left;
    width: auto;
    white-space: pre;
    

    &__function{
        width: fit-content;
        margin-bottom: 20px;
        font-weight: 700;
        max-height: 150px !important;
    overflow: scroll !important;
    }

    &__name{
        margin-bottom:20px;
    }
}

.slide_left:hover {
    box-shadow: inset 0 0 0 50px $pink;
  }
  
.buttons {
    display: flex;
    align-items: center;
    margin-top: 2rem;
    margin-left: -0.5rem;
    max-height: 48px;
}
  
.buttons button {
    margin: 0 0.5rem;
    background-color: #322037;
    height: 48px;
    text-align: center;
}
  
button.remove {
    color: var(--light);
    background-color: #B5A8AC;
}
  
h4 {
    margin-bottom: 1rem;
    margin-top: 2rem;
}
  
ol, ul {
    list-style: none;
}
  
li {
    line-height: 1.4;
}
  
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 50;
    text-align: left;
    padding: 15px;

    &-inner{
        background-color: $grey;
        padding: 1rem;
        border-radius: 0.5rem;
        width: 100%;
        max-width: 480px;
        overflow: auto;
        max-height: calc(100vh - 4rem);
    }

    &-inner form{
        width: 100%;
    }
}
  

  
.popup h2 {
    margin-bottom: 1rem;
}
  
.form-group {
margin-bottom: 1rem;
}

.form-group:last-of-type {
margin-bottom: 0;
}

input, textarea {
display: block;
width: 100%;
padding: 0.5rem;
resize: vertical;
max-height: 200px;
margin-bottom: 0.5rem;
}

.form-group label {
display: block;
margin-bottom: 0.5rem;
}

.form-group button {
margin-top: 1rem;
}

.hidden{
display: none;
}

.modalBackdrop{
    position:fixed;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    background-color:rgba(0,0,0,0.5);
}

.viewing{
    position: fixed;
    filter: brightness(1);
    max-height: fit-content;
    max-width: 50vh;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    z-index: 50;
}

.copied{
    font-weight: 800;
    font-size: larger;
    margin-left: 20px;
}
.function__container.function-code .function__function{
    font-size: 12px!important;
    width: 100%;
    height: 100%;
}