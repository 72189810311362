@import '../../abstracts/partials.scss';

form {
    background-color: #fff;
    border: 1px solid black;
    padding: 30px;
    position: relative;
    width: 50%;

    &::before {
        content: "";
        position: absolute;
        height: inherit;
        width: 100%;
        background-color: $pink;
        z-index: -1;
        top: 13px;
        left: 17px;
    }

    @include tablet {
        width: 95%;
    }
}

.submit-btn {
    z-index: 1;
    max-width: 140px;
    height: 45px;
    line-height: unset;
    margin-top: 10px;
}

.row{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.col-lg-6{
    width: 100%;
    display: flex;
    justify-content: center;
}
