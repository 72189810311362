@import '../../abstracts/partials.scss';

.fade-in {
    opacity: 0;
    transition: opacity 0.5s ease-in;
  }
  
  .fade-out {
    opacity: 0;
    transition: opacity 0.5s ease-out;
  }

.welcome{
    display: flex;
  justify-content: center;
  align-items: center;
  width: 20vw;
  height: 20vh;
  position: absolute;
  top: 20px;
  left: 20px;
  color: white;
  border-radius: 10px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.welcome.fade-in {
  opacity: 1;
}

.welcome p {
    font-size: 16px;
    text-align: center;
    padding: 10px;
  }

.homepage{
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: #B2A4A8;
    flex-direction: column;

    &__buttons-container{
        display: flex;
        flex-wrap: wrap;
        height: 50%;
        justify-content: space-around;
        width: 29%;
    }

    &__button{
        height: 70px;
        width: 220px;
        background-color: $purple;
    }

    &__logo{
        height: 200px;
        width: 200px;
    }

    &__nav{
        height: 70px;
    }
}

button {
    color: white;
    appearance: none;
    background: none;
    border: 1px solid $pink;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    padding: 18px 36px;
    font-weight: 500;
    text-transform: uppercase;
    display: inline-block;
    box-shadow: inset 0 0 0 0 $pink;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}

@media(max-width: 768px){
  .homepage{
    justify-content: unset!important;
    height: calc(100vh - 104px);

    &__buttons-container{
        display: flex;
        flex-direction: column;
        height: auto!important;
        justify-content: center;
        align-items: center;
        height: 100%!important;
        width: 100%;

    }
    &__nav{
      margin-bottom: 10px;
      margin-top: 10px;
    }
  }
}