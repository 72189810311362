@import '../../abstracts/partials.scss';

.login {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    &_btn-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 50%;
        max-width: 400px;
        position: relative;
        background-color: #fff;
        padding: 30px;
        border: 1px solid black;
        height: 300px;

        @include mobile {
            width: 90%;
        }

        &::before {
            content: "";
            position: absolute;
            height: inherit;
            width: 100%;
            background-color:$pink;
            z-index: -1;
            top: 13px;
            left: 17px;
        }
    }

    &_google-btn {
        color: #fff;
        appearance: none;
        background: $purple;
        border: 1px solid $pink;
        border-radius: 5px;
        outline: none;
        cursor: pointer;
        padding: 18px 36px;
        font-weight: 500;
        text-transform: uppercase;
        display: inline-block;
        letter-spacing: 1px;
        box-shadow: inset 0 0 0 0 $pink;
    -webkit-transition: ease-out 0.4s;
    -moz-transition: ease-out 0.4s;
    transition: ease-out 0.4s;
    }

    &_google-icon {
        margin-right: 15px;
    }

    &_unauth{
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 50vw;
        height: 50vh;
    }
}
