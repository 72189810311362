@import '../../abstracts/partials.scss';

#clients{
    margin-left: 50px;
    padding: 18px 36px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    background-color: #B2A4A8;
    color: $purple;
}
.function__description-cont{
    .function__description{
        white-space: initial;
    }
}

.function__name{
    text-transform: uppercase;
}
