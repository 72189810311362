@import "../../abstracts/partials.scss";

.header {
  display: flex;
  background-color: #b2a4a8;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  // padding: 0px 100px;

  &__logo {
    width: 200px;
    height: 60px;
  }

  &__buttons-container {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  &__nav {
    text-decoration: none;
    font-size: 20px;
    color: $purple;
    transition: ease-out 0.6s;
    padding: unset !important;
    text-transform: none !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px;
    border: unset!important;
    justify-content: center;

    svg {
      height: 15px;
      margin-left: 5px;
    }

    &:hover {
      color: $pink;
      transition: ease-out 0.4s;
    }
  }
}
.dropdown {
  float: left;
  overflow: hidden;
}

.navbar a:hover,
.dropdown:hover .dropbtn {
  background-color: red;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #b2a4a8;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  // margin-top: 31px;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}
